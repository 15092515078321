.icon-slider {
  z-index: 100;
  position: fixed;
  right: 0.133333rem;
  bottom: 0.8rem;
  width: 1.28rem;
  height: 1.28rem;
  box-shadow: 0 0 0.16rem 0 rgba(0, 41, 78, 0.2);
  border-radius: 50%;
}

.icon-slider img {
  width: 1.28rem;
  height: 1.28rem;
}

.slider {
  opacity: 0;
  z-index: 100;
  position: fixed;
  right: 1.546667rem;
  bottom: 0.8rem;
  width: 4.133333rem;
  height: 6.373333rem;
  background: #ffffff;
  border: 0.053333rem solid #00b551;
}

.slider > p {
  text-align: center;
  padding-top: 0.4rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 0.48rem;
}

.slider .tel {
  margin-top: 0.16rem;
  padding-left: 0.4rem;
}

.slider .tel div {
  display: flex;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.slider .tel div img {
  width: 0.506667rem;
  height: 0.666667rem;
  margin-right: 0.16rem;
}

.slider .tel div span {
  height: 0.666667rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141516;
  line-height: 0.853333rem;
  vertical-align: middle;
}

.slider > .title {
  margin-top: 0.293333rem;
  margin-bottom: 0.266667rem;
  text-align: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 0.533333rem;
}

.slider .btn-assessment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 0.266667rem;
  width: 3.2rem;
  height: 0.8rem;
  background: url(../../../assets/images/slider/bg_List.png) #0079e7 no-repeat;
  background-size: 100% 100%;
  border-radius: 0.053333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
}