.top-menu-box {
  position: fixed;
  height: 1.066667rem;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
}

.top-menu {
  height: 1.066667rem;
  padding: 0 0.533333rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: bold;
  color: #0079e7;
}

.top-menu .btn-menu {
  width: 0.533333rem;
  height: 0.32rem;
}

.top-menu .logo {
  width: 2.133333rem;
  height: 0.693333rem;
}

.top-menu span a {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  text-align: center;
  line-height: 0.8rem;
  vertical-align: middle;
  color: #0079e7;
}

.menu-box {
  height: calc(100vh - 1.066667rem);
  overflow-y: scroll;
  position: absolute;
  top: 1.066667rem;
  left: 0;
  width: 6.773333rem;
  background: #000;
  background: #0079e7;
  padding: 0 0.4rem;
  opacity: 0;
  z-index: 100;
}

.menu-box.heig {
  height: 12.8rem;
}

.menu-box > div {
  width: 6.773333rem;
  height: 1.066667rem;
}

.menu-box > div a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
}

.menu-box > div a.title {
  font-size: 0.373333rem;
}

.menu-box > div a img {
  width: 0.586667rem;
  height: 0.586667rem;
  margin-right: 0.266667rem;
  vertical-align: middle;
}

.menu-box > div a span:first-child {
  line-height: 1.066667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.menu-box > div a span:last-child {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.menu-box > div a span.maginLeft {
  margin-left: 0.3rem;
}