.layout-bottom-box {
  width: 100%;
  max-width: 10rem;
  padding-top: 0.533333rem;
  background-color: #ffffff;
}

.layout-bottom-box .sub-title {
  margin-bottom: 0.4rem;
}

.layout-bottom-box .sub-title p:first-child {
  text-align: center;
  font-size: 0.373333rem;
  font-family: Arial-BoldItalicMT, Arial;
  font-weight: normal;
  color: #0074c1;
  line-height: 0.426667rem;
  margin-bottom: 0.16rem;
}

.layout-bottom-box .sub-title p:last-child {
  text-align: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #00253e;
  line-height: 0.56rem;
}

.layout-bottom-box .qualifications {
  display: flex;
  justify-content: space-between;
  padding: 0 0.586667rem 0.493333rem;
  background-size: 10rem 0.466667rem;
  background-image: url(../../../assets/images/layout-bottom/bg.png);
  background-repeat: no-repeat;
  background-position: 0 0.8rem;
  position: relative;
}

.layout-bottom-box .qualifications .bg-color {
  width: 100%;
  height: 0.56rem;
  background: #0079e7;
  position: absolute;
  bottom: 0;
  left: 0;
}

.layout-bottom-box .qualifications img {
  width: 1.333333rem;
  height: 1.333333rem;
  box-shadow: 0 0 0.213333rem 0 rgba(0, 121, 231, 0.2);
  border-radius: 50%;
  z-index: 10;
}

.layout-bottom-box .infor {
  background: #0079e7;
}

.layout-bottom-box .infor > div {
  padding-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout-bottom-box .infor > div .infor-title {
  width: 1.28rem;
}

.layout-bottom-box .infor > div .en-infor-title {
  width: 100%;
  height: 0.666667rem;
  position: relative;
}

.layout-bottom-box .infor > div .en-infor-title span:first-child {
  font-size: 0.346667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #002b52;
  line-height: 0.48rem;
  position: absolute;
  top: 0;
  left: 5.04rem;
}

.layout-bottom-box .infor > div .en-infor-title span:first-child.us {
  left: 5.333333rem;
}

.layout-bottom-box .infor > div .en-infor-title span:last-child {
  font-size: 0.346667rem;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  line-height: 0.48rem;
  position: absolute;
  bottom: 0;
  left: 3.866667rem;
}

.layout-bottom-box .infor > div .infor-icon {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-top: 0.533333rem;
  margin-bottom: 0.133333rem;
}

.layout-bottom-box .infor > div p {
  font-size: 0.346667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 0.48rem;
}

.layout-bottom-box .infor > div p span {
  font-size: 0.373333rem;
}

.layout-bottom-box .infor > div.infor-service {
  padding-bottom: 0.4rem;
}

.layout-bottom-box .infor > div.infor-service .en-infor-title span:first-child {
  left: 4.426667rem;
}

.layout-bottom-box .infor > div.infor-service .en-infor-title span:last-child {
  left: 4.186667rem;
}

.layout-bottom-box .infor > div.infor-service p {
  font-size: 0.346667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.64rem;
}

.layout-bottom-box .infor .infor-follow {
  padding-bottom: 0.533333rem;
}

.layout-bottom-box .infor .infor-follow > p {
  font-weight: bold;
  margin-top: 0.533333rem;
}

.layout-bottom-box .infor .infor-follow .btns-follow {
  margin-top: 0.133333rem;
  width: 5.333333rem;
  line-height: 0.666667rem;
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0.493333rem;
  text-align: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.layout-bottom-box .infor .infor-follow .codes {
  margin-top: 0.533333rem;
  display: flex;
  padding-left: 0.6rem;
}

.layout-bottom-box .infor .infor-follow .codes img {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.533333rem;
  margin-bottom: 0.2rem;
}

.layout-bottom-box .infor .infor-follow .codes p {
  margin-right: 0.533333rem;
  text-align: center;
  font-weight: bold;
}

.layout-bottom-box .magin-top {
  margin-top: 0.4rem;
}

.layout-bottom-box .copyright {
  line-height: 0.693333rem;
  text-align: center;
  color: #767676;
  font-size: 0.32rem;
  font-weight: bold;
}